export default {
  props: {
    icon: {
      type: [String, Boolean],
      default: false,
    },
    layout: {
      type: String,
      default: "default",
    },
    notification: {
      type: [Object, Boolean],
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    safeNotification() {
      let res = { ...this.notification };
      if (!this.notification) {
        return false;
      }

      res.data = this.getSafeNotificationData(res?.data);

      return res;
    },
    action() {
      return false;
    }
  },
  methods: {
    getSafeNotificationData(notificationData) {
      let res = {};

      if (notificationData && typeof notificationData === "object") {
        res = { ...notificationData };
      }


      //todo: 3. intro layout
      //todo: 6. notification page - mark as read automatically
      // remaining after: emails, notification list widget, other be endpoints

      return res;
    },
  },
};
